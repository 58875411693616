import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheVoid extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The Void - Graham Ravey</h1>
                <div className="libraryImages">
                    <StaticImage src="../../../images/theVoid.jpg" alt="Sanchin"/>
                </div>

                <p>We hear much in the martial arts about "the void" and are told to attain this mental state is what
                    all martial artists irrespective of style should strive for. But little is written about what this
                    state is and how to attain it. I hope to clear this up in the next few paragraphs.</p>

                <p><strong>What is the void?</strong><br/>
                    We are told it is the mind of no mind, in other words it is when the mind is clear of thought right
                    NOW! and HERE!, not thinking of the past or the future. If we put these words together we get
                    NOWHERE! which exactly describes the "void". It's not in the past or the future. It's in the present
                    and the present SHOULD NOT be thought about only experienced through feelings. Once experienced it
                    is in the past and can then be thought about. I say SHOULD NOT because we are able to think about
                    and experience at the same time as feeling it. But if we do this will only clutter the mind and the
                    experience will not be savoured properly.</p>

                <p><strong>How do we attain the void?</strong><br/>
                    It is quite simple. Lots more training. This leads to muscle memory which will inevitably set the
                    mind free of thought in the present thus allowing the experience to be felt and savoured properly.
                </p>

                <p>If I can use kata as an example, we can see that if one has not practiced it enough and muscle memory
                    has not been attained then during the performance of it. One's mind will be full of thoughts such as
                    "What is the next move?" or "Is my stance correct" etc etc. And therefore is not free to feel and
                    experience each move of the kata as it unfolds. This is the difference between merely doing the kata
                    or becoming one with the kata. It is not wrong to say that at first we must have thought otherwise
                    how can we free our mind of it. But thought is only required in the beginning whilst learning the
                    kata.</p>

                <p>If you point a finger at the moon, Do not concentrate on the finger. Or you will miss the heavenly
                    glory.</p>

                <p>In this case the void is the moon and thought is the finger.<br/>
                    <strong>"Don't think, Feel"</strong></p>

                <p>Bruce Lee quoted these words in his movie "Enter the Dragon" but they have their roots much deeper in
                    Zen Buddhism.</p>

                <p><strong>Sensei Graham Ravey</strong></p>
            </Layout>);
    }
}

export default TheVoid;
